import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
      path: '/',
      redirect: '/login',
  },
  {
      path: '/login',
      component: () => import('@/views/Login/index.vue'),
  },
  {
      path: '/',
      component: () => import('@/views/Layout/index.vue'),
      children: [
  		{
  		    path: '/patient-appraisal',
  		    component: () => import('@/views/Appraisal/index.vue'),
  		    meta:{
  		        title:"测评管理",
  		        name:"测评管理"
  		    },
  		},
  		{
  		    path: '/patient-personal-center',
  		    component: () => import('@/views/PersonalCenter/index.vue'),
  		    meta:{
  		        title:"个人中心",
  		        name:"个人中心"
  		    },
  		},
  		{
  		    path: '/patient-report',
  		    component: () => import('@/views/Report/index.vue'),
  		    meta:{
  		        title:"报告管理",
  		        name:"报告管理"
  		    },
  		},
  		{
  		    path: '/admin-report-deil',
  		    component: () => import('@/views/reportResult/index.vue'),
  		}
  	  ]
  }
]



const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

router.beforeEach((to:any)=>{
    // if(to.meta.name){
    //     document.title = to.meta.name;
    // }else{
    //     document.title = '谦雅心理CT系统';
    // }
});
