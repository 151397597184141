import 'babel-polyfill'

import { createApp } from 'vue'
import App from './App.vue'
import { Dialog } from 'vant';
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import './assets/styles/index.less';
import 'vant/lib/index.css';
import debounce from '../src/utils/debounce.js'
// import * as ECharts from 'echarts'
// import Echarts from "./utils/echarts";
// import vants from './utils/vants.js';
// import { Button } from 'vant';
// import vant from 'vant';
import { createPinia } from "pinia";
const app = createApp(App)
app.mixin(debounce)
app.use(createPinia()).use(Dialog).use(router).mount('#app')